import { Error } from "./Error";

export const BadRequest = () => {
  return (
    <Error
      ErrorMessage="Bad Request."
      LogsHint="If you are the application owner check the logs for more information."
    />
  );
};
