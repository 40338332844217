import { UseFormRegister, FieldErrors, RegisterOptions } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { IFormInput } from "./ProgramForm";
import { Flex, Input } from "@amzn/ring-ui-react-components";

type InputProps = {
  placeholder: string;
  name: string;
  register: UseFormRegister<IFormInput>;
  validations: RegisterOptions<IFormInput, string> | undefined;
  errors: FieldErrors<IFormInput>;
};

export const FormInput = (props: InputProps) => {
  const inputStyles = {
    width: "100%",
    padding: "8px 15px",
  };
  return (
    <Flex
      style={{
        width: "100%",
        marginBottom: "15px",
        paddingRight: "30px",
        flexDirection: "column",
      }}
    >
      <Input
        style={inputStyles}
        placeholder={props.placeholder}
        {...props.register(props.name, props.validations)}
      />
      <ErrorMessage
        style={{ color: "#ff0000" }}
        errors={props.errors}
        name={props.name}
        as="span"
      />
    </Flex>
  );
};
