import { Flex } from "@amzn/ring-ui-react-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";
import { SuccessMessageOptions } from "../Confirmation";

export interface SuccessMessageProps {
  successMessage: Document;
}

export const SuccessMessage = (props: SuccessMessageProps) => {
  return (
    <Flex
      style={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "250px",
        marginTop: "50px",
      }}
    >
      {documentToReactComponents(props.successMessage, SuccessMessageOptions)}
    </Flex>
  );
};
