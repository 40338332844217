import { Config } from "./types";

export const prod: Config = {
  CONTENTFUL_API_BASE_URL: "https://graphql.contentful.com",
  CONTENTFUL_SPACE_ID: "cpcn7rmzq5g6",
  CAPTCHA_SCRIPT_URL:
    "https://4cfba0957187.us-east-2.captcha-sdk.awswaf.com/4cfba0957187/jsapi.js",
  CAPTCHA_API_KEY:
    "CO+z0eISnzZ9xWyop2Mavywpvc07CvxjP6LzbBuotcmBI9pvWxmrQg0/rhOtpS9vAsEaL5py/nslvnpX2Z9sbwrA2610XUJ4aiVGu5N/yRHK9h57fGyS8HZZqOohItPCvlGePA6vyzNTEZ6yETZKQjLYqfAlUYSHM+Uva+DPn5Q+7HXl5aCIy4DVwvVkJvRmZ7tiL4reIEZ+g6CEm4MvRuuT2oTzsG0OPARZgMS/EtNYIIQ7Gu3UFOo3QByw0FeaQ5Yrs0xTBtUTbaSLq1txTN3MvzqUX+676VhPNW17IYhmMgax0/TueZyQdUHgm8BdWu8lMMngEBcKQi2JiUp7BiQxpQTPtz5wb0QE/oMaOvqa2i/0VUkA94U4y6mVSYyzpTiXS7dy9K3Jyw0K6DqEHnxCvanUpA8UjUBYmyBGyFUejilH9VGzl7Gic92r5BLcFp1kef3VxnRzB+tZbmKide/dAutw96sTnvX7A7lIHmBmuAfbBkMvUTq0PP96ahtcqnwd0WHcrKuax71M/RNEyQ18wqK4ikAhsXhkRFx1uot3YF/yF7028RnEeDoHVHHsjSwfNN0h9L4Bv0Z/JDz3C1BvIe59ubwjdj3PwdTdfTGNL9I9rq/iwVg/sF6qUhca8tNLlu7ECzuSieWxKefC9s2K+xYpDgnxneyNRybxydE=_0_1",
  CONTENTFUL_API_TOKEN: "9lifIWR8BgqNp0EX_t4A2EbMKqlgjymV7P0Delyoxr0",
  CONTENTFUL_SPACE_ENVIRONMENT: "master",
  BACKEND_SUBMIT_URL: "https://service.communityprograms.ring.com/submit",
};
