import { Error } from "./Error";

export const GatewayTimeout = () => {
  return (
    <Error
      ErrorMessage="Gateway Timeout."
      LogsHint="If you are the application owner check the logs for more information."
    />
  );
};
