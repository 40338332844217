import type { GetProgramPageQuery } from "@amzn/ring-neighbors-contentful-gql-schema";
import GET_PROGRAM_PAGE from "@amzn/ring-neighbors-contentful-gql-schema/operations/cpp/getProgramPage.graphql";
import { useQuery } from "@apollo/client";
import { leadSourceType } from "../components/Form";
import { DefaultSuccessMessage } from "../components/Confirmation/DefaultSuccessMessage";

const mapProgramPageData = (rawData: GetProgramPageQuery | undefined) => {
  if (rawData && rawData.cppFormLayout) {
    return {
      programName: rawData.cppFormLayout.programHeader?.programName,
      programImageURL: rawData.cppFormLayout.programHeader?.programImage
        ?.url as string,
      programPageDescription:
        rawData.cppFormLayout.programHeader?.programPageDescription,
      leadSource: rawData.cppFormLayout.leadSource as string,
      compositeLeadSource: rawData.cppFormLayout
        .compositeLeadSource as leadSourceType,
      organizationType: rawData.cppFormLayout.organizationType as string,
      country: rawData.cppFormLayout.country as string,
      formFields: rawData.cppFormLayout.formFieldsCollection?.items as [],
      redirectionUrlPath: rawData.cppFormLayout.redirectionUrlPath as string,
      programDescription:
        rawData.cppFormLayout.programFooter?.programDescription?.json,
      startDate: rawData.cppFormLayout.startDate,
      endDate: rawData.cppFormLayout.endDate,
      programHeaderDescription:
        rawData.cppFormLayout.programHeader?.programDescription?.json,
      successMessage:
        rawData.cppFormLayout.successMessage?.json || DefaultSuccessMessage,
      isEmbeddedHTML: rawData.cppFormLayout.isEmbeddedHtml as boolean,
    };
  }
  return null;
};

export const useProgramPage = (id: string | undefined) => {
  const { data, ...rest } = useQuery<GetProgramPageQuery>(GET_PROGRAM_PAGE, {
    variables: { id },
    skip: id == null,
  });

  return {
    ...rest,
    data: mapProgramPageData(data),
  };
};
