import { isProd, isQA } from "../utils/constants";
import { beta } from "./config.beta";
import { gamma } from "./config.gamma";
import { prod } from "./config.prod";
import { Config } from "./types";

const getConfig = (): Config => {
  if (isProd) {
    return prod;
  }

  if (isQA) {
    return gamma;
  }

  return beta;
};

export const config = getConfig();
