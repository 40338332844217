import { ApolloClient, InMemoryCache } from "@apollo/client";
import { config } from "../config";

const {
  CONTENTFUL_API_BASE_URL: apiUrl,
  CONTENTFUL_SPACE_ID: spaceId,
  CONTENTFUL_SPACE_ENVIRONMENT: environment,
  CONTENTFUL_API_TOKEN: token,
} = config;

export const contentfulClient = new ApolloClient({
  uri: `${apiUrl}/content/v1/spaces/${spaceId}/environments/${environment}`,
  headers: {
    authorization: `Bearer ${token}`,
  },
  cache: new InMemoryCache(),
});
