import { Flex } from "@chakra-ui/layout";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ProgramDescriptionOptions } from "../ProgramDescription/ProgramDescriptionOptions";
import { Document } from "@contentful/rich-text-types";

export interface FooterProps {
  programDescription: Document;
}

export const ProgramFooter = (props: FooterProps) => {
  const { programDescription } = props;

  return (
    <section>
      {documentToReactComponents(programDescription, ProgramDescriptionOptions)}
    </section>
  );
};
