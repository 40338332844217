import { useEffect } from "react";

const defaultLocation = "https://ring.com/community-impact";
const HomePage = () => {
  useEffect(() => {
    window.location.replace(defaultLocation);
  }, []);

  return null;
};

export default HomePage;
