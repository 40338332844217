import { BLOCKS, INLINES } from "@contentful/rich-text-types";

export const SuccessMessageOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <a href={data.uri} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <div
        style={{
          fontSize: "2.25em",
          textAlign: "center",
        }}
      >
        {children}
      </div>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <div
        style={{
          fontSize: "3.75em",
          textAlign: "center",
        }}
      >
        {children}
      </div>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <div
        style={{
          fontSize: "2.75em",
          textAlign: "center",
          marginTop: "60px",
        }}
      >
        {children}
      </div>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <div
        style={{
          fontSize: "1.25em",
          textAlign: "center",
        }}
      >
        {children}
      </div>
    ),
  },
  renderText: (text) =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
};
