import { HelmetProvider } from "react-helmet-async";
import "./App.css";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./routes";
import { ProgramPage } from "./components/ProgramPage";
import HomePage from "./components/HomePage";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path={AppRoutes.Home} element={<HomePage />} />
          <Route path={AppRoutes.ProgramId} element={<ProgramPage />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
