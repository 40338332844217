import { useProgramPage, useProgramPageId } from "../hooks";
import { ProgramForm } from "./Form";
import { ProgramHeader } from "./ProgramHeader";
import { ProgramFooter } from "./ProgramFooter/ProgramFooter";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { NotFound } from "./Errors/NotFound";
import LoadingComponent from "./LoadingComponent";
import { format } from "date-fns";
import { Flex } from "@amzn/ring-ui-react-components";
import { AppHeader } from "./AppHeader";
import { AppFooter } from "./AppFooter";

export const ProgramPage = () => {
  const { id } = useParams();
  const programURLPath = id || "/";
  const { data: programId, loading: loadingProgramPageId } =
    useProgramPageId(programURLPath);
  const { data: programPage, loading: loadingProgramPage } =
    useProgramPage(programId);

  const navigate = useNavigate();
  useEffect(() => {
    if (programPage && programPage?.redirectionUrlPath) {
      navigate("/" + programPage.redirectionUrlPath);
    }
  }, [programPage]);
  if (loadingProgramPage || loadingProgramPageId) {
    return <LoadingComponent />;
  }
  const currentDate = new Date();
  const startDate = programPage?.startDate
    ? new Date(programPage.startDate)
    : null;
  const endDate = programPage?.endDate ? new Date(programPage.endDate) : null;
  const isFormReleased = startDate ? currentDate > startDate : true;
  const isFormExpired = endDate ? currentDate > endDate : false;
  const formattedEndDate = endDate ? format(endDate, "MMMM do, yyyy") : "N/A";
  const isEmbeddedHtml = programPage?.isEmbeddedHTML;
  const shouldRenderForm = programPage?.formFields?.length ?? 0;

  return (
    <>
      {!isEmbeddedHtml && <AppHeader />}
      <div className="main-content">
        {programPage && isFormReleased ? (
          <>
            {!isEmbeddedHtml && (
              <ProgramHeader
                programName={programPage?.programName}
                programImageURL={programPage?.programImageURL}
                programPageDescription={programPage?.programPageDescription}
                programHeaderDescription={programPage?.programHeaderDescription}
              />
            )}
            {isFormExpired && (
              <Flex style={{ alignItems: "center", flexDirection: "column" }}>
                <div
                  style={{
                    fontSize: "2rem",
                    fontWeight: "100",
                    marginTop: "60px",
                  }}
                >
                  This program is no longer accepting applications.
                </div>
                <div style={{ fontSize: "1rem" }}>
                  The application form was closed on {formattedEndDate}
                </div>
              </Flex>
            )}
            {shouldRenderForm ? (
              <ProgramForm
                formFields={programPage.formFields}
                programId={programId ? programId : ""}
                leadSource={programPage.leadSource}
                compositeLeadSource={programPage.compositeLeadSource}
                organizationType={programPage.organizationType}
                country={programPage.country}
                isFormExpired={isFormExpired}
                successMessage={programPage.successMessage}
              />
            ) : null}
            {!isEmbeddedHtml && (
              <ProgramFooter
                programDescription={programPage?.programDescription}
              />
            )}
          </>
        ) : (
          <div style={{ marginBottom: "220px", marginTop: "250px" }}>
            <NotFound />
          </div>
        )}
      </div>
      {!isEmbeddedHtml && <AppFooter />}
    </>
  );
};
