import { Config } from "./types";

export const gamma: Config = {
  CONTENTFUL_API_BASE_URL: "https://graphql.contentful.com",
  CONTENTFUL_SPACE_ID: "cpcn7rmzq5g6",
  CAPTCHA_SCRIPT_URL:
    "https://908c33f00e9d.us-east-2.captcha-sdk.awswaf.com/908c33f00e9d/jsapi.js",
  CAPTCHA_API_KEY:
    "qs7SinwmNvmIpjjOMQPtiIZ4COBX19nxeKy0BWazBN4ldX+ldFcf9H6CwS+fina2LeqVIAO/5HE+PnB0ciK+srQOWDO4Mv3b5y+ew/bwWrD8SVROU2faVBrfj4I647Ijl5E3kZtC3onAX47UdTy5ZvdPL9TQ4ExkVLItITfURDPfH+XHisqhU6iiM1fbVDx2Xm2hXbJAASmCAKPTevHIyR04n9RkOsCazWnqZONAC5E0ZDldFMvPeOaD6IHgaX1IpPEdgf6COk20qfMBsypyY4cDmQcFSQlId/HkHUwE/S3HvwzhTwl56Q1UmT19q2PKZty9GEEsq3fIzCZ55NwQJAv+Jv6lzvmKSO8dYuRUoReC8qLcsCIb/wTURBTLNoNgLBO396XOg3JjMzdQn4/BrfXH4Lhy+sT1TgpWb4pZtt1DG3Tz84z9hgP/cM9MZcAf13B2qHbwdZ461Rtn4vcUdvRwZRuT22PSJL5hNLqEgcgPccpwD+84wyX5XrMgeSp4JBDB0s0Z0l6g0KOVITIfe+MtHy93rrnUpXi+c0F1v1BXsWiqIo0/je8ZgMm5CSimsBeLSfqSgerLsBl6255xgcwAz1qk3EJhHKFYE9u5l/K/RSXJOCWbr/6IYYt4hNxOwJtWiltFB4Ai/RwuNGWSeMNtBymz3c7vdFQUQfQxzCM=_0_1",
  CONTENTFUL_API_TOKEN: "CvL7gw5I_BckkovaXeBqkkcyukW-QrKnaJOD2mYG7WY",
  CONTENTFUL_SPACE_ENVIRONMENT: "qa",
  BACKEND_SUBMIT_URL: "https://service.communityprograms.qa.ring.com/submit",
};
