import { Config } from "./types";

export const beta: Config = {
  CONTENTFUL_API_BASE_URL: "https://graphql.contentful.com",
  CONTENTFUL_SPACE_ID: "cpcn7rmzq5g6",
  CONTENTFUL_API_TOKEN: "0xLWPKJ8M7w9GjQOFvqNjwoeVyn98wQhPIrzDisquFE",
  CONTENTFUL_SPACE_ENVIRONMENT: "dev",
  CAPTCHA_SCRIPT_URL:
    "https://071b2415a7ee.us-east-2.captcha-sdk.awswaf.com/071b2415a7ee/jsapi.js",
  CAPTCHA_API_KEY:
    "QPl35nebsHKVsaPzYyIeRIC/dvCug1CQ8qm4SCT1a/wkxGVECEaE2G0CWafnrKWaAUPKhQlVawh3oiMIeWO77ip5XlDkKxeGh0Douz0J/xAd4sCdNy8O9T2F1FjSj+TdX4ePl4D6n11DvBIBLLlCNRnCpsIesj+zypGFW6Cpx34B71uYu2w5vrJjWxbUsUxTrXtvFYwyUttfNfVgrLVQQkCqZI9ogD/71mjmdeh9ouxiyloNB3MZnVj4jGnOFtR0f1Mb5RXR1pmS49lB8Ny1f3fCVRNbYAtbDtlSKR7uQTP5swX1fKpoFC0ttPDw5OKT76CMXsVu8LRZHOAwFZqVHpOZX5TNqsNNnCG/NXb5FbKg3EldX43fSavhbUNIpYPuhJ4SZB66FAeXfdn70k0byr4MdPEq6f0V6J+7D7HqjxxMmpCWDoK2+TPYtQyjCosfAPtb17gUDKbrJbOZAHVKB2iXC9S8TnwV0AdGg/HoRvg1wm7dUIFC/Q3JENBfqG738PdiYTI0tZTRdAecSSLM+zfc3XnfAmkpRsk+vxxJCs8pPVSEw1oaXYkyp1hY2mCOYwHZ2NtHAu7LwE+39eWR5GaBP2C+W1XDbPXhiJ2/QOILieQ9HjgawNr8utz0rqbbm/bUHZKDxjnOdMnPVTyMiZaenAaMgvvxV5S1djRpWck=_0_1",
  BACKEND_SUBMIT_URL: "https://service.communityprograms.dev.ring.com/submit",
};
