import { RegisterOptions } from "react-hook-form";

export const getFormValidations = (rowItem: any) => {
  const validationJson: RegisterOptions = {
    ...(rowItem.isRequired && {
      required: {
        value: true,
        message: `This Field is Required`,
      },
    }),
    ...(rowItem.maxLength && {
      maxLength: {
        value: rowItem.maxLength,
        message: `${rowItem.placeholder} cannot exceed ${rowItem.maxLength} characters`,
      },
    }),
    ...(rowItem.minLength && {
      minLength: {
        value: rowItem.minLength,
        message: `${rowItem.placeholder} should be less than ${rowItem.minLength} characters`,
      },
    }),
  };

  switch (rowItem.type) {
    case "ALPHANUMERIC":
      validationJson.pattern = {
        value: /^[a-z0-9]+$/i,
        message: `${rowItem.placeholder} is Invalid`,
      };
      break;
    case "ALPHANUMERICSPECIAL":
      validationJson.pattern = {
        value: /^[ A-Za-z0-9_@./#&+-]*$/,
        message: `${rowItem.placeholder} is Invalid`,
      };
      break;
    case "EMAIL":
      validationJson.pattern = {
        value:
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        message: `${rowItem.placeholder} is Invalid`,
      };
      break;
    case "NUMERIC":
      validationJson.pattern = {
        value: /^[0-9]+$/,
        message: `${rowItem.placeholder} is Invalid`,
      };
      break;
    case "PHONE":
      validationJson.pattern = {
        value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        message: `${rowItem.placeholder} is Invalid`,
      };
      break;
    case "TEXT":
      validationJson.pattern = {
        value: /^[a-zA-Z ,.'-]+$/,
        message: `${rowItem.placeholder} is Invalid`,
      };
      break;
    case "URL":
      validationJson.pattern = {
        value:
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
        message: `${rowItem.placeholder} is Invalid`,
      };
      break;
    case "TEXTAREA":
      validationJson.pattern = {
        value: /^$|[a-zA-Z0-9 ,.'-()!?]+$/,
        message: `Invalid input`,
      };
      break;
  }
  return validationJson;
};
