import { Error } from "./Error";

export const NotFound = () => {
  return (
    <Error
      ErrorMessage="The page you were looking for doesn't exist."
      ErrorHint="You may have mistyped the address or the page may have moved."
      LogsHint="If you are the application owner check the logs for more information."
    />
  );
};
