import "./Error.css";

interface ErrorProps {
  ErrorMessage: string;
  ErrorHint: string;
  LogsHint: string;
}

export const Error = ({ ErrorMessage, ErrorHint, LogsHint }: ErrorProps) => {
  return (
    <div className="default-error-page">
      <div className="dialog">
        <div>
          <h1>{ErrorMessage}</h1>
          {ErrorHint && <p>{ErrorHint}</p>}
        </div>
        <p>{LogsHint}</p>
      </div>
    </div>
  );
};
