import { Checkbox } from "@amzn/ring-ui-react-components";
import { UseFormRegister, FieldErrors, RegisterOptions } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { IFormInput } from "./ProgramForm";

export interface FormCheckboxProps {
  label: string | undefined;
  name: string;
  register: UseFormRegister<IFormInput>;
  validations: RegisterOptions<IFormInput, string> | undefined;
  errors: FieldErrors<IFormInput>;
}

export const FormCheckbox = (props: FormCheckboxProps) => {
  return (
    <>
      <Checkbox
        style={{ marginTop: "10px", marginBottom: "15px" }}
        size="md"
        {...props.register(props.name, props.validations)}
      >
        {props.label}
      </Checkbox>
      <ErrorMessage errors={props.errors} name={name} as="span" />
    </>
  );
};
