import RingLogo from "@images/ring_logo.svg";

export const AppFooter = () => {
  return (
    <footer className="footer-custom">
      <div className="container-fluid">
        <div className="footer-header">
          <a href="/" className="footer-logo">
            <img src={RingLogo} />
          </a>

          <a
            className="footer-get-app"
            href="https://go.onelink.me/v1xd/6ea13ff4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get The App
          </a>
        </div>
      </div>
    </footer>
  );
};
