export const RingGlobalNavSiteURL = "https://nav.ring.com/globalnav.min.js";
export const RingGlobalNavSiteCSSURL =
  "https://d2a12jsx2jznj5.cloudfront.net/globalnav.min.css";
const { hostname } = window.location;
export const isDev =
  hostname === "web.communityprograms.dev.ring.com" ||
  hostname === "communityprograms.dev.ring.com";
export const isQA =
  hostname === "web.communityprograms.qa.ring.com" ||
  hostname === "communityprograms.qa.ring.com";
export const isProd =
  hostname === "web.communityprograms.ring.com" ||
  hostname === "communityprograms.ring.com";
