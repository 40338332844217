import React from "react";

const LoadingComponent = () => {
  const spinnerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  const spinnerCircleStyle = {
    border: "4px solid rgba(0, 0, 0, 0.3)",
    borderTop: "4px solid #007bff",
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    animation: "spin 1s linear infinite",
  };

  const keyframes = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
    <div style={spinnerStyle}>
      <style>{keyframes}</style>
      <div style={spinnerCircleStyle}></div>
    </div>
  );
};

export default LoadingComponent;
