import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { config } from "../../config";
import { Helmet } from "react-helmet-async";
import { IFormInput } from "../Form";
import { NotFound } from "../Errors/NotFound";
import { InternalServerError } from "../Errors/InternalServerError";
import { BadRequest } from "../Errors/BadRequest";
import { GatewayTimeout } from "../Errors/GatewayTimeout";
import { Error } from "../Errors/Error";
import { SuccessMessage } from "../Form/SuccessMessage";
import { Document } from "@contentful/rich-text-types";

const MAX_RETRY_COUNT = 2;

interface captchaProps {
  entryId: string;
  entries: IFormInput;
  markAsSubmitted: (e: any) => void;
  successMessage: Document;
}
const CaptchaDiv = forwardRef(
  (props: captchaProps, ref: React.Ref<unknown> | undefined) => {
    const [retryCount, setRetryCount] = useState(0);
    const [showCaptchaModal, setShowCaptchaModal] = useState(false);
    const hideCaptchaModal = () => {
      setShowCaptchaModal(false);
    };
    const renderCaptcha = () => {
      if (retryCount > MAX_RETRY_COUNT) {
        failureCaptcha(new Error("Max retries reached"));
        return;
      }
      let container = document.querySelector("#captcha-div");
      AwsWafCaptcha.renderCaptcha(container, {
        apiKey: config.CAPTCHA_API_KEY,
        onSuccess: successCaptcha,
        onError: failureCaptcha,
        onLoad: onLoadCaptcha,
        dynamicWidth: false,
      });
    };

    const onLoadCaptcha = () => {
      let paraContainer = document.getElementById("message-body");
      paraContainer?.remove();
    };

    const successCaptcha = (token: string) => {
      AwsWafIntegration.fetch(config.BACKEND_SUBMIT_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          entryId: props.entryId,
          entries: props.entries,
        }),
      })
        .then((r) => {
          if (!r.ok) {
            if (r.status === 400) {
              props.markAsSubmitted(<BadRequest />);
            } else if (r.status === 404) {
              props.markAsSubmitted(<NotFound />);
            } else if (r.status === 500) {
              props.markAsSubmitted(<InternalServerError />);
            } else if (r.status === 504) {
              props.markAsSubmitted(<GatewayTimeout />);
            } else {
              props.markAsSubmitted(
                <Error
                  ErrorMessage="We're sorry, but something went wrong."
                  LogsHint="If you are the application owner check the logs for more information."
                />
              );
            }
            return r.json();
          } else {
            props.markAsSubmitted(
              <SuccessMessage successMessage={props.successMessage} />
            );
          }
        })
        .then((data) => {
          hideCaptchaModal();
        })
        .catch((error) => {
          props.markAsSubmitted(
            <Error
              ErrorMessage="We're sorry, but something went wrong."
              LogsHint="If you are the application owner check the logs for more information."
            />
          );
        });
    };

    const failureCaptcha = (error: Error) => {
      setRetryCount(retryCount + 1);
    };
    useImperativeHandle(ref, () => ({
      displayCaptchaModal() {
        setShowCaptchaModal(true);
      },
    }));
    useEffect(() => {
      if (showCaptchaModal) {
        renderCaptcha();
      }
    }, [showCaptchaModal]);
    const modalOverlay = {
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "1000",
    };
    const modal = {
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "5px",
      position: "relative",
      zIndex: "1001",
      width: "80%",
      maxWidth: "600px",
    };
    const close = {
      position: "absolute",
      top: "10px",
      right: "10px",
      cursor: "pointer",
    };
    return (
      <>
        <Helmet>
          <script
            type="text/javascript"
            src={config.CAPTCHA_SCRIPT_URL}
            defer
          ></script>
        </Helmet>
        {showCaptchaModal ? (
          <div style={modalOverlay}>
            <div style={modal}>
              <span style={close} onClick={hideCaptchaModal}>
                &times;
              </span>
              <div>
                <div id="message"></div>
                <div id="captcha-div"></div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
);

export default CaptchaDiv;
