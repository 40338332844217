export const DefaultSuccessMessage: any = {
  nodeType: "document",
  data: {},
  content: [
    {
      nodeType: "heading-5",
      data: {},
      content: [
        {
          nodeType: "text",
          value: "Thank you for the submission!",
          marks: [],
          data: {},
        },
      ],
    },
    {
      nodeType: "paragraph",
      data: {},
      content: [
        {
          nodeType: "text",
          value: "We've received your information.",
          marks: [],
          data: {},
        },
      ],
    },
  ],
};
