import { Image, Flex } from "@amzn/ring-ui-react-components";
import LeftArrow from "@images/left_arrow.png";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ProgramDescriptionOptions } from "../ProgramDescription";
import { useEffect, useState } from "react";
import { useMobileView } from "../../hooks";

export interface HeaderProps {
  programName: string | null | undefined;
  programImageURL: string | undefined;
  programPageDescription: string | null | undefined;
  programHeaderDescription: Document;
}

export const ProgramHeader = (props: HeaderProps) => {
  const {
    programName,
    programImageURL,
    programPageDescription,
    programHeaderDescription,
  } = props;
  const mobileView = useMobileView();
  return (
    <>
      <Flex className="container">
        <Flex width="100%" position="relative">
          <Image
            width="100%"
            height="300px"
            objectFit="cover"
            src={programImageURL}
          />
          <Flex
            className="overlay"
            flexDirection="column"
            justifyContent="center"
          >
            <Flex position="absolute" top="5%" left="2%">
              <a href="/">
                <img src={LeftArrow} height={26} width={16} />
              </a>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
              <Flex
                style={{
                  marginBottom: "10px",
                  fontSize: mobileView ? "1.5em" : "2.25em",
                }}
              >
                {programName}
              </Flex>

              <Flex
                style={{
                  width: mobileView ? "80%" : "50%",
                  margin: "0 auto",
                  fontSize: mobileView ? "1em" : "1.5em",
                  lineHeight: "normal",
                  textAlign: "center",
                }}
              >
                {programPageDescription}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <section>
        {documentToReactComponents(
          programHeaderDescription,
          ProgramDescriptionOptions
        )}
      </section>
    </>
  );
};
