import type { GetProgramPageIdQuery } from "@amzn/ring-neighbors-contentful-gql-schema";
import GET_PROGRAM_PAGE_ID from "@amzn/ring-neighbors-contentful-gql-schema/operations/cpp/getProgramPageId.graphql";
import { useQuery } from "@apollo/client";

export const useProgramPageId = (programURLPath: String) => {
  const { data, ...rest } = useQuery<GetProgramPageIdQuery>(
    GET_PROGRAM_PAGE_ID,
    {
      variables: {
        programURLPath,
      },
    }
  );

  return {
    ...rest,
    data: data?.cppFormLayoutCollection?.items[0]?.sys?.id,
  };
};
