import { Button, Flex } from "@amzn/ring-ui-react-components";
import { FormCheckbox, FormInput, FormLabel, FormTextarea } from ".";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { getFormValidations } from "./FormValidations";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useRef, useState } from "react";
import CaptchaDiv from "../CaptchaModal/CaptchaModal";
import Select from "react-select";
import { Document } from "@contentful/rich-text-types";
import { useMobileView } from "../../hooks";

export interface ProgramFormProps {
  formFields: any[];
  programId: string;
  leadSource: string;
  compositeLeadSource?: leadSourceType;
  organizationType: string;
  country: string;
  isFormExpired: boolean;
  successMessage: Document;
}

export interface leadSourceType {
  dependsOn: string;
  keyValueMapping: any;
}

export interface IFormInput {
  [key: string]: any;
}

export const ProgramForm = (props: ProgramFormProps) => {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<IFormInput>();
  const [responseComponent, setResponseComponent] = useState(null);
  const captchaRef = useRef();
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    captchaRef.current.displayCaptchaModal();
    createPayload(getValues());
  };
  const mobileView = useMobileView();
  const markAsSubmitted = (component: any) => {
    setResponseComponent(component);
  };

  const sendButton = {
    width: "30%",
    lineHeight: "1.42857143",
    padding: "12px 20px 12px 20px",
    fontSize: "16px",
    margin: "45px auto 30px auto",
    border: "0px",
    minWidth: "200px",
    borderRadius: "9999px",
    color: "#fff",
    background: "#f16522",
  };
  const watermarkStyle = {
    position: "absolute",
    width: "700px",
    height: "100%",
    "background-color": "#f2f2f2b0",
    "z-index": "10000",
    "font-size": "5rem",
    "text-align": "center",
    color: "#5c5c5c",
    "font-weight": "100",
    "line-height": "95px",
    "flex-direction": "column",
    "justify-content": "center",
  };

  const createPayload = (formData: IFormInput) => {
    for (var key in formData) {
      if (typeof formData[key] == "boolean") {
        formData[key] = formData[key] == "true" ? 1 : 0;
      } else if (typeof formData[key] == "object") {
        if (Object.prototype.toString.call(formData[key]) == "[object Date]") {
          formData[key] =
            formData[key].getFullYear() +
            "-" +
            (formData[key].getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            formData[key].getDate().toString().padStart(2, "0");
        } else {
          formData[key] = formData[key].value;
        }
      }
    }

    if (props.country != "N/A") {
      formData["Country"] = props.country;
    }

    if (props.organizationType != "N/A") {
      formData["Organization_Type__c"] = props.organizationType;
    }

    if (props.leadSource != "N/A") {
      formData["LeadSource"] = props.leadSource;
    } else {
      if (props.compositeLeadSource?.dependsOn) {
        const leadSource = formData[props.compositeLeadSource?.dependsOn];
        formData["LeadSource"] =
          props.compositeLeadSource?.keyValueMapping[leadSource];
        delete formData[props.compositeLeadSource?.dependsOn];
      }
    }

    return formData;
  };

  const createOptions = (options: any) => {
    if (options == undefined) return undefined; // TO-DO : Add error handling
    return Object.keys(options).map((key) => {
      return { value: options[key], label: key };
    });
  };

  return (
    <Flex style={{ position: "relative", justifyContent: "center" }}>
      {responseComponent ? (
        <div style={{ marginBottom: "220px", marginTop: "250px" }}>
          {responseComponent}
        </div>
      ) : (
        <>
          {props.isFormExpired && (
            <Flex style={watermarkStyle}>
              This program is no longer accepting applications.
            </Flex>
          )}
          <Flex
            marginLeft="auto"
            marginRight="auto"
            maxWidth="700px"
            flexDirection="column"
            as="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            {props.formFields.map((row) => {
              return (
                <Flex
                  key={row.rowId}
                  value={row.rowId}
                  style={{ flexDirection: mobileView ? "column" : "row" }}
                >
                  {row.rowCollection.items.map((rowItem: any, index: any) => {
                    const formFieldType = rowItem.__typename;
                    switch (formFieldType) {
                      case "CppFormFieldlabel":
                        return (
                          <FormLabel
                            labelData={rowItem.title}
                            labelAlignment={rowItem.alignment}
                            labelSize={rowItem.size}
                          />
                        );
                      case "CppFormFieldinput":
                        if (rowItem.type == "TEXTAREA")
                          return (
                            <FormTextarea
                              placeholder={rowItem.placeholder}
                              register={register}
                              name={rowItem.htmlName}
                              errors={errors}
                              validations={getFormValidations(rowItem)}
                            />
                          );
                        else if (rowItem.type == "DATE")
                          return (
                            <div
                              style={{ width: "100%", paddingRight: "30px" }}
                            >
                              {rowItem.label && (
                                <FormLabel
                                  labelData={rowItem.label}
                                  labelAlignment={"L"}
                                  labelSize={"M"}
                                />
                              )}
                              <Controller
                                control={control}
                                name={rowItem.htmlName}
                                rules={{ required: rowItem.isRequired }}
                                render={({ field: { ...fieldProps } }) => {
                                  return (
                                    <ReactDatePicker
                                      {...fieldProps}
                                      className={`custom-react-datepicker-input ${rowItem.htmlName}`}
                                      wrapperClassName="custom-react-datepicker-wrapper"
                                      placeholderText={rowItem.placeholder}
                                      selected={
                                        fieldProps.value
                                          ? new Date(fieldProps.value)
                                          : null
                                      }
                                      minDate={
                                        rowItem.dateValidations == "FUTURE_DATE"
                                          ? new Date()
                                          : null
                                      }
                                      maxDate={
                                        rowItem.dateValidations == "PAST_DATE"
                                          ? new Date()
                                          : null
                                      }
                                    />
                                  );
                                }}
                              />
                              {errors[rowItem.htmlName] && (
                                <span style={{ color: "#ff0000" }}>
                                  This Field is Required
                                </span>
                              )}
                            </div>
                          );
                        return (
                          <FormInput
                            placeholder={rowItem.placeholder}
                            register={register}
                            name={rowItem.htmlName}
                            errors={errors}
                            validations={getFormValidations(rowItem)}
                          />
                        );
                      case "CppFormFieldSelect": {
                        if (rowItem.type == "DROPDOWN") {
                          return (
                            <div
                              style={{
                                width: "100%",
                                paddingRight: "30px",
                                marginBottom: "15px",
                              }}
                            >
                              {rowItem.label && (
                                <FormLabel
                                  labelData={rowItem.label}
                                  labelAlignment={"L"}
                                  labelSize={"M"}
                                />
                              )}
                              <Controller
                                name={rowItem.htmlName}
                                control={control}
                                rules={{ required: rowItem.isRequired }}
                                render={({ field: { ...fieldProps } }) => (
                                  <Select
                                    {...fieldProps}
                                    options={createOptions(rowItem.options)}
                                    placeholder={rowItem.placeholder}
                                  />
                                )}
                              />
                              {errors[rowItem.htmlName] && (
                                <span style={{ color: "#ff0000" }}>
                                  This Field is Required
                                </span>
                              )}
                            </div>
                          );
                        } else if (rowItem.type == "CHECKBOX") {
                          return (
                            <FormCheckbox
                              name={rowItem.htmlName}
                              label={rowItem.placeholder}
                              register={register}
                              errors={errors}
                              validations={getFormValidations(rowItem)}
                            />
                          );
                        }
                      }
                      case "CppFormFieldempty":
                        return <></>;
                    }
                  })}
                </Flex>
              );
            })}
            <Button
              type="submit"
              style={sendButton}
              onClick={handleSubmit(onSubmit)}
            >
              Send
            </Button>
            <CaptchaDiv
              entryId={props.programId}
              entries={createPayload(getValues())}
              markAsSubmitted={markAsSubmitted}
              ref={captchaRef}
              successMessage={props.successMessage}
            />
          </Flex>
        </>
      )}
    </Flex>
  );
};
