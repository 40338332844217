import { BLOCKS } from "@contentful/rich-text-types";

export const ProgramDescriptionOptions = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
    [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
    [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
    [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
    [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
    [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
    [BLOCKS.DOCUMENT]: (node, childen) => (
      <div
        style={{
          maxWidth: "700px",
          margin: "auto",
          color: "#424242",
          fontSize: "1.25em",
          lineHeight: "150%",
        }}
      >
        {childen}
      </div>
    ),
    [BLOCKS.PARAGRAPH]: (node, childen) => <div>{childen}</div>,
  },
  renderText: (text) =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
};
