import React from "react";
import "./Form.css";
import { useMobileView } from "../../hooks";
export interface FormLabelProps {
  labelSize: string | null;
  labelAlignment: string | null;
  labelData: string | null;
}

export const FormLabel = (props: FormLabelProps) => {
  const labelAlignment = LabelAlignment(props.labelAlignment);
  const labelContent = LabelSize(
    props.labelSize,
    props.labelData,
    labelAlignment
  );
  const labelStyles = {
    flexGrow: "1",
    width: "100%",
  };

  return <label style={labelStyles}>{labelContent}</label>;
};

const LabelSize = (
  size: string | null,
  content: string | null,
  alignment: React.CSSProperties
) => {
  const mobileView = useMobileView();
  switch (size) {
    case "S":
      const h3Styles = {
        marginTop: "25px",
        fontSize: "1.5em",
        fontWeight: "100",
        ...alignment,
      };
      return <h3 style={h3Styles}>{content}</h3>;
    case "M":
      const h2Styles = {
        maxWidth: "650px",
        fontSize: mobileView ? "1.25em" : "1.5em",
        color: "#808080",
        lineHeight: "normal",
        fontWeight: "100",
        ...alignment,
      };
      return <h2 style={h2Styles}>{content}</h2>;
    case "L":
      const h1Styles = {
        marginTop: "50px",
        fontSize: mobileView ? "1.75em" : "2.75em",
        color: "#424242",
        fontWeight: "100",
        ...alignment,
      };
      return <h1 style={h1Styles}>{content}</h1>;
    case "XS":
      return <p style={{ paddingTop: "5px" }}>{content}</p>;
  }
};

const LabelAlignment = (alignment: string | null): React.CSSProperties => {
  switch (alignment) {
    case "C":
      return { textAlign: "center" };
    case "R":
      return { textAlign: "right" };
    default:
      return { textAlign: "left" };
  }
};
