import { Error } from "./Error";

export const InternalServerError = () => {
  return (
    <Error
      ErrorMessage="We're sorry, but something went wrong."
      LogsHint="If you are the application owner check the logs for more information."
    />
  );
};
